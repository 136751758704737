
import { defineComponent, onMounted, watch } from 'vue';
import { ref } from 'vue';
export type ArrowDirection = 'up' | 'right' | 'down' | 'left';

interface Props {
  id: string;
  size: string;
  rotate: boolean;
  ready: boolean;
  action?: Function;
}

export default defineComponent({
  props: {
    id: { type: String, required: true },
    size: { type: String, default: 'auto' },
    rotate: Boolean,
    ready: Boolean,
    action: Function,
  },
  setup(props: Props) {
    const rotateClass = ref<boolean>(props.rotate);

    function listener() {
      rotateClass.value = props.rotate;
    }

    watch(
      () => props.rotate,
      n => {
        if (n) rotateClass.value = true;
      },
    );

    onMounted(() => {
      const element = document.getElementById(props.id);
      if (element) element.addEventListener('animationiteration', listener, false);
    });

    return {
      rotateClass,
    };
  },
});
