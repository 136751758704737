
import useSession from '@/composables/session';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Login Button',
  setup() {
    const href = ref('/login');
    const text = ref('Login');

    const { quickVerify } = useSession();

    const verifiedSession = quickVerify();

    if (verifiedSession) {
      href.value = '/app';
      text.value = 'Get Started';
    }

    return { href, text };
  },
});
