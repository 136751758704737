
import BudgetSelect from '@/components/Nav/BudgetSelect.vue';
import Settings from '@/components/Nav/Settings.vue';
import Title from '@/components/Nav/Title.vue';
import NavItem from '@/components/Nav/NavTopItem.vue';
import { computed, defineComponent, nextTick, ref } from 'vue';
import useYnab from '@/composables/ynab';
import useSession from '@/composables/session';
import router from '@/router';

type NavPage = 'budgets' | 'settings' | null;

export default defineComponent({
  name: 'Nav',
  components: { BudgetSelect, Settings, Title, NavItem },
  setup() {
    const navPage = ref<NavPage>(null);
    const { state, clearState: clearYnabState } = useYnab();
    const { clearState: clearSessionState } = useSession();

    const budgetId = computed(() => state.selectedBudgetId);

    if (state.selectedBudgetId === null) navPage.value = 'budgets';

    function setNavPage(page: NavPage) {
      if (navPage.value === page) navPage.value = null;
      else navPage.value = page;
    }

    function logout() {
      clearYnabState();
      clearSessionState();
      nextTick(() => router.replace({ name: 'Landing' }));
    }

    return {
      navPage,
      setNavPage,
      budgetId,
      logout,
    };
  },
});
