
import { WorthDate } from '@/composables/types';
import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/runtime-core';
import Currency from '@/components/General/Currency.vue';

export default defineComponent({
  components: { Currency },
  props: {
    netWorth: {
      type: Array as PropType<WorthDate[]>,
      default: () => [],
    },
  },
  setup(props) {
    const value = computed(() => {
      const first = props.netWorth[0]?.worth ?? 0;
      const last = props.netWorth[props.netWorth.length - 1]?.worth ?? 0;

      return last - first;
    });

    return { value };
  },
});
