
import { computed, defineComponent, PropType } from 'vue';
import { ChartData, ChartOptions } from 'chart.js';
import LineGraph from '@/components/Graphs/LineGraph.vue';
import Logo from '@/components/General/Logo.vue';
import BrynabLogo from '@/components/General/BrynabLogo.vue';
import Underline from '@/components/General/Underline.vue';
import LoginButton from '@/components/Landing/LoginButton.vue';
import useSettings from '@/composables/settings';

export default defineComponent({
  components: { LineGraph, Logo, BrynabLogo, Underline, LoginButton },
  props: {
    data: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    options: {
      type: Object as PropType<ChartOptions>,
      required: true,
    },
  },
  setup() {
    const ynabReferral = process.env.VUE_APP_YNAB_REFERRAL;

    const { state: settings } = useSettings();
    const brynab = computed(() => settings.brynab);

    return { ynabReferral, brynab };
  },
});
