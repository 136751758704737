
import { WorthDate } from '@/composables/types';
import { computed, defineComponent } from '@vue/runtime-core';
import { PropType } from 'vue';

export default defineComponent({
  props: {
    netWorth: {
      type: Array as PropType<WorthDate[]>,
      default: () => [],
    },
  },
  setup(props) {
    const diffs = computed(() => {
      if (props.netWorth.length === 0) return [0];
      return props.netWorth.map(({ worth }, index, all) => {
        if (index === 0) return 0;
        return worth - all[index - 1].worth;
      });
    });

    const positives = computed(() => diffs.value.reduce((acc, cur) => (cur >= 0 ? acc + 1 : acc)));
    const negatives = computed(() => diffs.value.reduce((acc, cur) => (cur < 0 ? acc + 1 : acc)));

    return { positives, negatives };
  },
});
