
import { defineComponent } from 'vue';
import Nav from '@/components/Nav/Nav.vue';
import useYnab from '@/composables/ynab';

export default defineComponent({
  name: 'Main',
  components: { Nav },
  setup() {
    const { selectedBudgetId } = useYnab();
    return { selectedBudgetId };
  },
});
