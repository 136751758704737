
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: {
    label: String,
    action: Function,
    size: {
      type: String,
      default: 'large',
    },
  },
});
