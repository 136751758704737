
import { defineComponent, PropType } from '@vue/runtime-core';
export type ArrowDirection = 'up' | 'right' | 'down' | 'left';

export default defineComponent({
  props: {
    direction: {
      type: String as PropType<ArrowDirection>,
      default: 'up',
    },
  },
});
