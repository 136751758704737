
import { formatCurrency } from '../../services/helper';
import Arrow from '@/components/Icons/ArrowUpIcon.vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    number: {
      type: Number,
      required: true,
    },
    arrow: Boolean,
    full: Boolean,
  },
  components: { Arrow },
  setup(props) {
    const value = computed(() => formatCurrency(Math.abs(props.number), props.full));

    return { value };
  },
});
