
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    selected: Boolean,
    color: {
      type: String,
      default: 'blue',
    },
  },
});
