
import { defineComponent, PropType } from '@vue/runtime-core';
import Underline from '@/components/General/Underline.vue';

export default defineComponent({
  name: 'Nav Top Item',
  components: { Underline },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    click: Function,
    side: {
      type: String as PropType<'left' | 'middle' | 'right'>,
      default: 'left',
    },
  },
});
