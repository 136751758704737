
import useYnab from '@/composables/ynab';
import { computed, defineComponent } from '@vue/runtime-core';

export default defineComponent({
  name: 'Nav Title',
  setup() {
    const { state } = useYnab();
    const budgetName = computed(() => state.selectedBudgetName);
    return { budgetName };
  },
});
