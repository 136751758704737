
import { computed, defineComponent } from 'vue';
import { ref } from 'vue';

type ButtonState = 'ready' | 'hover' | 'down' | 'up';
type LoginStatus = 'pending' | 'loggedIn' | 'loggedOut';
interface Props {
  override: boolean;
}

export default defineComponent({
  props: {
    override: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: Props) {
    const buttonState = ref<ButtonState>('ready');
    const loginStatus = ref<LoginStatus>('loggedOut');

    function ynabLogin() {
      const url = `${process.env.VUE_APP_API}/auth/ynab/login`;
      loginStatus.value = 'pending';
      location.replace(url);
    }

    function mouseDownEvent() {
      if (buttonState.value !== 'up') {
        buttonState.value = 'down';
      }
    }

    function mouseUpEvent() {
      if (buttonState.value !== 'up') {
        buttonState.value = 'up';
      }

      setTimeout(() => {
        ynabLogin();
      }, 2000);
    }

    function mouseEnterEvent() {
      if (buttonState.value !== 'up') {
        buttonState.value = 'hover';
      }
    }
    function mouseLeaveEvent() {
      if (buttonState.value !== 'up') {
        buttonState.value = 'ready';
      }
    }

    const message = computed(() => {
      if (props.override === true) return 'Success!';
      else if (loginStatus.value === 'loggedOut') return 'Get Started';
      else if (loginStatus.value === 'loggedIn') return 'Success!';
      else if (buttonState.value === 'up') return 'Logging in';
      else return 'Logging in';
    });

    return {
      mouseDownEvent,
      mouseUpEvent,
      mouseEnterEvent,
      mouseLeaveEvent,
      message,
      loginStatus,
      buttonState,
    };
  },
});
