
import ArrowRightCircleIcon from '@/components/Icons/ArrowRightCircleIcon.vue';
import { defineComponent } from 'vue';
import useSettings from '@/composables/settings';

export default defineComponent({
  name: 'Settings',
  components: { ArrowRightCircleIcon },
  setup(_, { emit }) {
    const { getBrynab, setBrynab, isDummy, setDummy } = useSettings();

    function done() {
      emit('done');
    }

    return { done, getBrynab, setBrynab, isDummy, setDummy };
  },
});
