<template>
  <div class="logo">
    <svg
      class="row-span-2 self-center fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      enable-background="new 0 0 20 20"
      xml:space="preserve"
    >
      <path
        d="M0.69,11.331l1.363,0.338l1.026-1.611l-1.95-0.482c-0.488-0.121-0.981,0.174-1.102,0.66 C-0.094,10.719,0.202,11.209,0.69,11.331z M18.481,11.592l-4.463,4.016l-5.247-4.061c-0.1-0.076-0.215-0.133-0.338-0.162 l-0.698-0.174l-1.027,1.611l1.1,0.273l5.697,4.408c0.166,0.127,0.362,0.189,0.559,0.189c0.219,0,0.438-0.078,0.609-0.232 l5.028-4.527c0.372-0.334,0.401-0.906,0.064-1.277C19.428,11.286,18.854,11.256,18.481,11.592z M8.684,7.18l4.887,3.129 c0.413,0.264,0.961,0.154,1.24-0.246l5.027-7.242c0.286-0.412,0.183-0.977-0.231-1.26c-0.414-0.285-0.979-0.182-1.265,0.23 l-4.528,6.521L8.898,5.165C8.694,5.034,8.447,4.991,8.21,5.042c-0.236,0.053-0.442,0.197-0.571,0.4L0.142,17.209 c-0.27,0.422-0.144,0.983,0.28,1.25c0.15,0.096,0.319,0.141,0.486,0.141c0.301,0,0.596-0.149,0.768-0.42L8.684,7.18z"
      />
    </svg>
    <div>
      <span class="text-5xl">br</span
      ><span class="leading-none font-semibold text-7xl tracking-tight">YNAB</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.logo {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, min-content));
  max-height: 96px;
  column-gap: 4px;
}

.logo > svg {
  min-width: 70px;
}
</style>
